import { fetchLocalStorageData } from './local-storage';

export function appendLeadIdToUrl(url: string): string {
  const leadId = fetchLocalStorageData<string>('lead_id');

  if (!leadId) {
    return url;
  }

  const [baseUrl, hash] = url.split('#');
  const separator = baseUrl.includes('?') ? '&' : '?';
  const enhancedUrl = `${baseUrl}${separator}ajs_uid=${leadId}`;
  
  return hash ? `${enhancedUrl}#${hash}` : enhancedUrl;
}