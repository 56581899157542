'use client'

import Link from 'next/link'

export function NavLink({ href, children }) {
  return (
    <Link
      href={href}
      className="inline-block rounded-lg px-1 py-1 text-md font-medium text-slate-300 hover:text-slate-50"
    >
      {children}
    </Link>
  )
}
